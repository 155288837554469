import Container from '../../components/container/main_container'
import { QuizzPreview } from '../../components/quizz/quizz_preview/quizz_preview'
import { ScrollView, View } from 'react-native'
import { useQuizzList } from '../../services/users_api/users_api'
import { ActivityIndicator } from '../../components/loaders'
import { useActiveUser } from '../../services/user'
import {
  base_color,
  Heading,
  space,
  Text,
  useNormalize,
} from '@unboared/base-ui.all'
import { NavBar } from '../../components/navbar'

/**
 * The screen with all the quizzes for an user
 */
export function QuizzesForUser({ userID }: { userID: string }) {
  const { normalize } = useNormalize()
  const { user: me } = useActiveUser()
  const quizzes = useQuizzList(userID)

  return (
    <>
      <Container>
        <ScrollView
          style={{
            flex: 1,
            marginTop: normalize(space.huge),
            alignSelf: 'stretch',
          }}
        >
          <Heading
            style={{ marginVertical: normalize(space.small) }}
            text={`Bonjour ${me.username} 👋`}
          />
          <Text
            style={{ color: base_color.light_secondary }}
            preset="large"
            text="Mes quizz"
          />
          <View
            style={{
              flex: 1,
              alignSelf: 'stretch',
              marginVertical: normalize(space.small),
              flexWrap: 'wrap',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {quizzes === undefined ? (
              <ActivityIndicator />
            ) : (
              quizzes.map((quizz: any, index: number) => (
                <QuizzPreview key={index} {...quizz} />
              ))
            )}
          </View>
        </ScrollView>
      </Container>
      <NavBar activeNav="me" />
    </>
  )
}

export function QuizzesScreen({ route }: any) {
  return <QuizzesForUser userID={route.params.userID} />
}
