import { StyleSheet, View, ViewStyle } from 'react-native'
import HomeContainer from '../../container/home_container'
import {
  accent_color,
  Button,
  Heading,
  space,
  Text,
  useNormalize,
} from '@unboared/base-ui.all'
import { useCallback, useState } from 'react'
import { useActiveUser } from '../../../services/user'
import { usersAPI } from '../../../services/users_api'
import { ActivityIndicator } from '../../loaders'

export const DeleteConfirmationPopup = ({
  name,
  uuid,
  uid,
  author,
  questions,
  onCancel,
}: any) => {
  const { normalize } = useNormalize()
  const { user: me } = useActiveUser()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const removeQuizz = useCallback(() => {
    setLoading(true)
    if (me) {
      usersAPI
        .removeQuizz(me.uid, uuid)
        .then(onCancel)
        .catch(() => {
          setLoading(false)
          setErrorMessage('Erreur lors de la suppression du quizz.')
        })
    }
  }, [])

  return (
    <HomeContainer>
      <View style={styles.container}>
        <Heading type="h2">
          {uid !== author ? 'Enlever de ma liste' : 'Supprimer le Quizz'}
        </Heading>
        <Heading type="h3" style={{ color: accent_color.important }}>
          {name}
        </Heading>
        {errorMessage && (
          <Text style={{ color: accent_color.error }} text={errorMessage} />
        )}
        <Text
          style={{
            maxWidth: normalize(300),
            marginVertical: normalize(space.small),
          }}
        >
          En cliquant sur le bouton ci-dessous, vous confirmez vouloir{' '}
          {uid !== author ? (
            <>
              supprimer le quizz{' '}
              <Text style={{ fontWeight: 'bold' }}>{name}</Text> de cette liste.
            </>
          ) : (
            <>
              supprimer définitivement les{' '}
              <Text style={{ fontWeight: 'bold' }}>
                {questions.length} questions
              </Text>{' '}
              du quizz <Text style={{ fontWeight: 'bold' }}>{name}</Text> .
            </>
          )}
        </Text>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Button
            preset={'primary'}
            text="Je souhaite supprimer ce Quizz"
            onPress={removeQuizz}
          />
        )}
      </View>
    </HomeContainer>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  buttonContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
