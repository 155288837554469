import { useCallback, useState } from 'react'

export enum UnQuizzState {
  SHOW_READY,
  SHOW_QUESTION,
  SHOW_PROPOSALS,
  SHOW_ANSWER,
}

export const useUnQuizzGame = () => {
  const [questionIndex, setQuestionIndex] = useState(0)
  const [state, setState] = useState(0)

  const nextQuestion = useCallback(() => {
    setQuestionIndex((state) => state + 1)
  }, [])

  const nextState = useCallback(() => {
    setState((state) => {
      if (state === UnQuizzState.SHOW_ANSWER) {
        nextQuestion()
        return 1
      }
      return state + 1
    })
  }, [])

  return { questionIndex, state, nextState }
}
