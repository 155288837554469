import { useEffect, useState } from 'react'
import { View, StyleSheet, TouchableOpacity, Pressable } from 'react-native'

import { ProposalButtonGamepad } from '../../../components/buttons/proposal_button_gamepad'
import {
  Button,
  Text,
  accent_color,
  space,
  useNormalize,
} from '@unboared/base-ui.all'

import { QCM } from './qcm'
import { addOrRemove } from '../../../utils'
import { UnQuizzState } from '../../../quizz/inQuizz/components/UnQuizz'
import { QuestionGamepadProps } from '../types'
import { CheckBox } from '../../../components/input/checkbox'
import { BUTTON_COLORS } from '../../../const'

/**
 * The component on the gamepad (related to the type of question)
 * @param {param QuestionGamepadProps} param props - properties for question gamepad
 * @returns the QCM gamepad component
 */
export const QcmGamepad = ({
  question,
  vote,
  onVote,
  gameState,
  canVote,
}: QuestionGamepadProps) => {
  const qcm = question as QCM
  const [answer, setAnswer] = useState<Array<unknown>>([])

  // Reinit answer field
  useEffect(()=>{
    setAnswer([])
  },[question])

  const updateAnswer = (proposal: unknown) => {
    if (canVote && proposal) {
      setAnswer((prevAnswer: Array<unknown>) => {
        let newAnswer = [...prevAnswer]
        addOrRemove(proposal, newAnswer)
        return newAnswer
      })
    }
  }

  return (
    <View
      style={[
        styles.center,
        {
          flex:1,
          width: '100%',
          height: '100%',
        },
      ]}
    >
      <Text preset='light' text="Plusieurs réponses possibles"/>
      <View style={[styles.center, styles.flexRow]}>
        {qcm.getProposals().map((proposal, i) => (
          <ProposalButton
            key={i}
            i={i}
            onPress={() => updateAnswer(proposal)}
            disable={!isInGuess(proposal, vote, answer, gameState)}
          />
        ))}
      </View>
      {canVote && (
        <Button
          style={[
            styles.center,
            {
              backgroundColor: vote
                ? accent_color.success
                : accent_color.important,
            },
          ]}
          text={vote ? 'Réponses validées' : 'Valider les réponses'}
          onPress={() => onVote(answer)}
        />
      )}
    </View>
  )
}


const ProposalButton = ({ i, onPress, disable }: any) => {
  const { normalize } = useNormalize()

  return (
    <View
      style={{
        width: '50%',
        alignSelf: 'stretch',
        padding: normalize(space.smaller),
      }}
    >
      <ProposalButtonGamepad id={i} onPress={onPress} disable={disable} />

      <Pressable style={{position : 'absolute', top: 2 * normalize(space.smaller), right : 2 * normalize(space.smaller)}} onPress={onPress}>
        <CheckBox checked={!disable} color={BUTTON_COLORS[i]} />
      </Pressable>
    </View>
  )
}

const isInGuess = (
  proposal: unknown,
  vote: unknown,
  answer: Array<unknown>,
  gameState?: UnQuizzState,
) => {
  if (vote) {
      if (vote instanceof Array<unknown>){
        return vote.includes(proposal)
      }
    } else {
      return gameState === UnQuizzState.SHOW_ANSWER || answer.includes(proposal)
    }
}

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexRow: {
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})
