import { DEFAULT_QUESTION_DURATION } from "../../const"
import { QuestionType } from "./types"

/**
 * This question class is used in the application.
 */
export class Question {
    question: string
    duration: number
    anecdote?: string

    constructor(question: string, duration: number = DEFAULT_QUESTION_DURATION, anecdote: string = "") {
        this.question = question
        this.duration = duration
        this.anecdote = anecdote
    }

    getQuestion() {
        return this.question
    }

    getDuration() {
        return this.duration
    }

    getAnecdote() {
        return this.anecdote
    }

    checkAnswer(ans: unknown): boolean {
        throw "[checkAnswer] Question is an abstract class"
    }

    getProposalsComponent(): any {
        throw "[getProposalsComponent] Question is an abstract class"
    }

    getCreatorComponent(): any {
        throw "[getCreatorComponent] Question is an abstract class"
    }

    getAnswerComponent(): any {
        throw "[getAnswerComponent] Question is an abstract class"
    }

    getGamepadComponent(): any {
        throw "[getGamepadComponent] Question is an abstract class"
    }

    getType(): QuestionType {
        throw "[getType] Question is an abstract class"
    }
}