import Container from '../../components/container/main_container'
import { ActivityIndicator } from '../../components/loaders'
import { useAnalyticsAccessEvent } from '../../services/analytics'
import { useActiveUser } from '../../services/user'
import { QuizzesForUser } from '../quizzes'

/** The user home */
export function HomeScreen({ navigation, route }: any) {
  useAnalyticsAccessEvent('home')
  const { user } = useActiveUser()
  if (!user) {
    return (
      <Container style={{ justifyContent: 'center' }}>
        <ActivityIndicator />
      </Container>
    )
  }
  return <QuizzesForUser userID={user.uid} />
}
