import { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from '.';

export const logAnalyticsEvent = (eventName: string, data = {}) => {
    //   if (isProdMode()) {
    logEvent(analytics, eventName, data);
    //   } else {
    //     console.log(`[ANALYTICS] logEvent - ${eventName}`);
    //   }
};

export const useAnalyticsAccessEvent = (eventName: string, data = {}) => {
    useEffect(() => {
        logAnalyticsEvent(eventName, data);
        // eslint-disable-next-line
    }, []);
};
