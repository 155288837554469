import { QuestionType } from "../types"
import { Question } from "../Question"
import { QcmProposals } from "./QcmProposals"
import { QcmCreator } from "./QcmCreator"
import { QcmAnswer } from "./QcmAnswer"
import { QcmGamepad } from "./QcmGamepad"


/**
 * The model related to the "Questions à choix multiple".
 */
export class QCM extends Question {
    answers: Array<unknown>
    proposals: Array<unknown>

    constructor(question: string, duration: number, anecdote: string, answers: Array<unknown>, proposals: Array<unknown>) {
        super(question, duration, anecdote)
        this.answers = answers
        this.proposals = proposals
    }

    checkAnswer(answers: unknown) {
        if (answers instanceof Array<unknown>) {
            if (answers.length !== this.answers.length) {
                return false
            }
            let remains = this.answers.length
            for (let answer of answers) {
                let ind = this.answers.findIndex((value) => value === answer)
                if (ind == -1) {
                    return false
                }
                remains -= 1
            }
            return (remains === 0)
        }
        else {
            if (this.answers.length === 1 && this.answers[0] === answers) {
                return true
            } else {
                return false
            }
        }
    }

    includeAnswer(answer: unknown) {
        return this.answers.includes(answer)
    }

    getProposal(i: number): unknown {
        return this.proposals[i]
    }

    getProposals(): Array<unknown> {
        return this.proposals
    }

    getAnswers(): Array<unknown> {
        return this.answers
    }

    getProposalsComponent(): any {
        return QcmProposals
    }

    getAnswerComponent(): any {
        return QcmAnswer
    }

    getCreatorComponent(): any {
        return QcmCreator
    }

    getGamepadComponent(): any {
        return QcmGamepad
    }

    getType(): QuestionType {
        return QuestionType.QCM
    }
}
