import { space, useNormalize, useTheme } from '@unboared/base-ui.all'
import { View} from 'react-native'
import { Header } from '../header'

/** Container when the user is not yet authentified */
export default function HomeContainer({ onHome, style, children }: any) {
  const theme = useTheme()
  const { normalize } = useNormalize()

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        backgroundColor: theme.backgroundColor,
        padding: normalize(space.medium),
      }}
    >
      {onHome && (
        <Header logoStyle="secondary" style={{ flex: 0.05 }} onPress={onHome} />
      )}
      <View
        style={[
          {
            flex: 0.95,
            alignItems: 'center',
            alignSelf: 'stretch',
          },
          style,
        ]}
      >
        {children}
      </View>
    </View>
  )
}
