import { space, useNormalize } from '@unboared/base-ui.all'
import { View } from 'react-native'

export const TopRightContent = ({ children }: any) => {
  const { normalize } = useNormalize()
  return (
    <View
      style={{
        position: 'absolute',
        alignSelf: 'center',
        right: normalize(space.medium),
        top: normalize(space.medium),
      }}
    >
      {children}
    </View>
  )
}
