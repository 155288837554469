/* The user type */
export type User = {
  uid: string
  username: string
  infos: any
  quizz: Array<string>
}

/* The authentification state type  */
export type UserState = {
  user: User
  loading: boolean
  errorMessage: string
  createUser: (uid: string, username?: string, infos?: any) => Promise<void>
  loadUserData: (uid: string) => void
  setUser: (user: any) => void
  logout: () => void
}

/* The default user state value */
export const initialUserState = {
  user: undefined,
  loading: true,
  errorMessage: '',
}

/**
 * The converter instance from / to player instance
 */
export const userConverter = {
  toFirestore: (user: User) => {
    return { ...user }
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return { ...data }
  },
}
