import { View } from 'react-native'
import { Heading, Text, base_color } from '@unboared/base-ui.all'

export const PreviewInfos = ({
  infos,
  questions,
}: {
  infos: any
  questions: Array<unknown>
}) => {
  return (
    <View>
      <Heading
        type="h3"
        style={{ maxWidth: '85%' }}
        text={infos.name}
        numberOfLines={1}
      />
      <Text
        style={{ color: base_color.light_secondary }}
        text={`${questions.length} questions`}
      />
    </View>
  )
}
