import React, { useEffect, useState } from 'react'
import { Pressable } from 'react-native'

import {
  Button,
  Heading,
  Text,
  TextInput,
  useNormalize,
  base_color,
  useTranslate,
  useTheme,
} from '@unboared/base-ui.all'
import { useMargin } from '../hooks/useMargin'
import { PasswordInput } from './PasswordInput'
import { useAuth } from '../../../../services/auth'
import { useLinkTo } from '@react-navigation/native'

export const EmailPasswordSignIn = ({ onSuccessSignIn }: any) => {
  const { normalize } = useNormalize()
  const { translate } = useTranslate()
  const { smallMargin, mediumMargin, largeMargin } = useMargin()

  const linkTo = useLinkTo()

  const {
    status,
    email,
    password,
    changeEmail,
    changePassword,
    emailErrorMessage,
    passwordErrorMessage,
    errorMessage,
    login,
    onForgotPassword,
  } = useAuth()

  useEffect(() => {
    if (status === 'RESET_PASSWORD') {
      linkTo(`/auth/mail?mail=${email}`)
    }
  }, [email, status])

  const onSubmit = () =>
    login(email, password)
      .then((auth) => {
        onSuccessSignIn(auth)
      })
      .catch(() => {
        console.error('Error login')
      })

  return (
    <>
      <Heading type="h2" tx="common.signin.title" />
      <Text
        style={[smallMargin, { color: base_color.light_secondary }]}
        tx="common.signin.description"
      />
      <TextInput
        style={{ height: normalize(30) }}
        containerStyle={mediumMargin}
        text={email}
        onChangeText={changeEmail}
        textContentType="emailAddress"
        placeholder={translate(
          'common.signin.email_password.email.placeholder',
        )}
        returnKeyType="next"
        helperText={translate(emailErrorMessage)}
        warning={emailErrorMessage}
      />
      <PasswordInput
        password={password}
        onSubmitEditing={onSubmit}
        onChangeValue={changePassword}
        passwordErrorMessage={translate(passwordErrorMessage)}
      />
      <ForgotPasswordButton onPress={onForgotPassword} />
      <Button
      textPreset='medium'
        style={smallMargin}
        tx="common.signin.email_password.submitButton"
        onPress={onSubmit}
      />
    </>
  )
}

export const ForgotPasswordButton = ({ onPress }: { onPress: () => void }) => {
  const { largeMargin } = useMargin()
  const theme = useTheme()

  return (
    <Pressable onPress={onPress}>
      <Text
        style={{
          ...largeMargin,
          color: theme.color.primary,
          fontWeight: 'bold',
          alignSelf: 'flex-end',
        }}
        tx="common.signin.email_password.forgotPassword.message"
      />
    </Pressable>
  )
}
