import { useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import {
  space,
  useTheme,
  TextInput,
  useNormalize
} from '@unboared/base-ui.all'
import { ProposalButton } from '../../../components/buttons/proposal_button'
import { CheckBox } from '../../../components/input/checkbox'

/**
 * The component on the gamepad (related to the type of question)
 * @param {param QuestionGamepadProps} param props - properties for question gamepad
 * @returns the QCM gamepad component
 */
export const QcmCreator = ({ question, setQuestion }: any) => {
  const { normalize } = useNormalize()
  const theme = useTheme()

  useEffect(() => {
    if (question.proposals === undefined) {
      setQuestion((old: any) => ({
        ...old,
        proposals: ['', '', '', ''],
      }))
    }
  }, [question])

  useEffect(() => {
    if (question.answer === undefined || !Array.isArray(question.answer)) {
      setQuestion((old: any) => ({
        ...old,
        answer: [],
      }))
    }
  }, [question])

  const updateProposal = (proposalIndex: number, newText: string) => {
    setQuestion((old: any) => {
      let newQuestion = { ...old }
      if (proposalIndex < newQuestion.proposals.length) {
        // Gets the index of the answer in the list of answers
        let indexInAnswers = old.answer.findIndex(
          (elem: string) => elem == old.proposals[proposalIndex],
        )
        
        // Update proposal and answer if checked
        newQuestion.proposals[proposalIndex] = newText
        if (indexInAnswers !== -1) {
          newQuestion.answer[indexInAnswers] = newText
        }
      }
      return newQuestion
    })
  }

  const updateAnswer = (proposalIndex: number) => {
    setQuestion((old: any) => {
      let newQuestion = { ...old }

      // Gets the index of the checked answer
      let index = old.answer.findIndex(
        (elem: string) => elem == old.proposals[proposalIndex],
      )
      if (index !== -1) {
        newQuestion.answer.splice(index, 1)
      } else {
        newQuestion.answer.push(old.proposals[proposalIndex])
      }
      return newQuestion
    })
  }

  return (
    <View
      style={{
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {[0, 1, 2, 3].map((proposal, i) => (
        <View
          key={i}
          style={{ width: '50%', padding: normalize(space.smaller) }}
        >
          <ProposalButton id={i}>
            <TextInput
              containerStyle={{
                width: '90%',
                alignItems: 'center',
              }}
              style={{
                width: '100%',
                borderWidth: 0,
                outline: 'none',
                fontFamily: theme.secondaryTitleFont,
                fontSize: normalize(theme.sizeH2),
              }}
              placeholder={`Indiquez réponse ${String.fromCharCode(65 + i)}`}
              text={question.proposals ? question.proposals[i] : ''}
              onChangeText={(text: string) => updateProposal(i, text)}
            />
            <TouchableOpacity onPress={() => updateAnswer(i)}>
              <CheckBox
                checked={
                  question.proposals &&
                  question.answer.includes(question.proposals[i])
                }
              />
            </TouchableOpacity>
          </ProposalButton>
        </View>
      ))}
    </View>
  )
}
