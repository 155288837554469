import React from 'react'
import { StyleSheet, View } from 'react-native'
import {
  Heading,
  useNormalize,
  space,
  size,
  useTheme,
} from '@unboared/base-ui.all'
import { BUTTON_COLORS, DISABLE_OPACITY } from '../../const'

export type ProposalProps = {
  /** The index of the proposal */
  id: number
  /** If the button should be disable */
  disable?: boolean
  /** The content of the button */
  children: any
}

/**
 * A component to display proposals on the main screen.
 */
export const ProposalButton = ({ id, children, disable }: ProposalProps) => {
  const A_code = 65
  const { normalize } = useNormalize()
  const theme = useTheme()

  return (
    <View style={styles(normalize, disable, id).container}>
      <View
        style={[
          styles(normalize).identifier,
          { borderRightColor: theme.backgroundColor },
        ]}
      >
        <Heading
          style={{ fontFamily: 'OpenSansBold' }}
          text={String.fromCharCode(A_code + id)}
        />
      </View>
      <View style={styles(normalize).content}>{children}</View>
    </View>
  )
}

const styles = (
  normalize: (x: number) => number,
  disable?: boolean,
  id?: number,
) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      width: '100%',
      height: normalize(50),
      borderRadius: normalize(size.borderRadius.small),
      backgroundColor: BUTTON_COLORS[id || 0],
      opacity: disable ? DISABLE_OPACITY : 1,
    },
    identifier: {
      width: normalize(40),
      alignItems: 'center',
      justifyContent: 'center',
      borderRightWidth: normalize(space.tiny),
      padding: normalize(space.tiny),
    },
    content: {
      flex: 1,
      flexDirection: 'row',
      paddingHorizontal: normalize(space.medium),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
