import { Pressable } from 'react-native'
import { Icon, useTheme } from '@unboared/base-ui.all'

export const DeleteQuizzButton = ({ style, onPress }: any) => {
  const theme = useTheme()

  return (
    <Pressable style={[{ alignSelf: 'center' }, style]} onPress={onPress}>
      <Icon name="io-trash" color={theme.buttonColor} size={20} />
    </Pressable>
  )
}
