import { useEffect } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'
import { createGlobalStorage } from '@unboared/utils.storage'

import { db as firebaseDB } from '../users_api'
import { usersAPI } from '../users_api/users_api'
import { initialUserState, User, UserState } from './user_state'

/* The store that keep informations about the authentification */
export const useActiveUser = createGlobalStorage<UserState>(
  (set: any, get: any) => ({
    ...initialUserState,

    /**
     * This function create a new user in the database and store it as a global variable.
     */
    createUser: async (uid: string, username?: string, infos?: any) => {
      set((state: UserState) => ({ ...state, loading: true, errorMessage: '' })) // start loading

      let newUser: any = { uid: uid }
      if (username) {
        newUser = { ...newUser, username: username }
      }
      if (infos) {
        newUser = { ...newUser, infos: infos }
      }

      // user correctly added to database
      const onSuccess = () => {
        get().setUser(newUser)
      }

      // user not added to database
      const onFailure = (error: string) => {
        get().failure(error)
      }

      return usersAPI.createUser(newUser).then(onSuccess).catch(onFailure)
    },

    /**
     * This function loads the user data as a global variable.
     * @param uid the unique user identifier
     */
    loadUserData: (uid: string) => {
      set((state: UserState) => ({ ...state, loading: true, errorMessage: '' })) // start loading

      // user found in the database
      const onSuccess = (user: any) => {
        get().setUser(user)
      }

      // user not found in database
      const onFailure = (error: string) => {
        get().failure(error)
      }

      if (uid) {
        usersAPI.getUser(uid).then(onSuccess).catch(onFailure)
      } else {
        get().resetErrorMessage()
      }
    },

    /**
     * Set the authentified user.
     */
    setUser: (user: User) => set({ loading: false, user: user }),

    /**
     * This function resets the error message.
     */
    resetErrorMessage: () => set({ loading: false, errorMessage: '' }),

    /**
     * Set the authentified user.
     */
    failure: (error: string) => set({ loading: false, errorMessage: error }),

    /**
     * Logout the current user
     */
    logout: () => set({ user: undefined }),
  }),
)

/**
 * Hook that is call to keep a global variable on the authentified user.
 */
export const useActiveUserManager = (id: string) => {
  const loadUserData = useActiveUser((state) => state.loadUserData)

  useEffect(() => {
    if (id) {
      const docRef = doc(firebaseDB, 'users', id)
      const unsub = onSnapshot(docRef, (doc) => {
        loadUserData(id)
      })
      return unsub
    }
  }, [id])

  useEffect(() => {
    loadUserData(id)
  }, [id])
}
