import {
  Icon,
  CheckBox as UBCheckBox,
  useNormalize,
} from '@unboared/base-ui.all'
import { View, ViewStyle } from 'react-native'
import { accent_color, base_color } from '@unboared/base-ui.all'

const CHECKBOX_SIZE = 25

export const CheckBox = ({
  checked,
  color = accent_color.success,
  size = CHECKBOX_SIZE,
}: {
  checked?: boolean
  color?:string
  size?: number
}) => {
  const { normalize } = useNormalize()
  const checkboxStyle = {
    display: 'flex',
    width: normalize(size),
    height: normalize(size),
    backgroundColor: checked ? color : 'rgba(255,255,255,0.7)',
    borderRadius: normalize(0.33333 * size),
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: normalize(0.1 * size),
    borderColor: base_color.dark_secondary,
    cursor: 'pointer'
  } as ViewStyle

  return (
    <View style={checkboxStyle}>
      {checked && (
        <Icon name="io-checkmark-sharp" size={0.66666 * size} color="white" />
      )}
    </View>
  )
}
