import { createUserWithEmailAndPassword } from "firebase/auth"
import { auth as firebaseAuth } from ".."
import { authError } from "../auth_error"
import { AuthState } from "../auth_state"


export const signup = async (set: any, get: any, newletterSubscription: boolean) => {
    const promiseUser = new Promise((resolve, reject) => {
        // Start loadinga
        set((state: AuthState) => ({ ...state, loading: true, errorMessage: '' }))

        const { email, password, loginEmailFailure, loginPasswordFailure } = get()

        if (email === "") {
            loginEmailFailure("common.signin.email_password.email.missing_email")
        }
        else if (password === "") {
            loginPasswordFailure("common.signin.email_password.password.missing_password")
        }
        else {
            createUserWithEmailAndPassword(firebaseAuth, email, password)
                .then((userCredential: any) => {
                    const user = userCredential.user;
                    console.log("USER AUTH", user)
                    onSuccessSignUp(email, newletterSubscription, user)
                    resolve(user)
                })
                .catch((error: any) => {
                    onErrorSignUp(set, get, error)
                    reject(error)
                })
        }
    })
    return promiseUser

}

const onErrorSignUp = (set: any, get: any, error: any) => {
    const { loginEmailFailure, loginPasswordFailure } = get()

    if (authError.isAlreadyInUse(error)) {
        loginEmailFailure("common.signin.email_password.email.already_in_use")
    }
    else if (authError.isInvalidEmail(error)) {
        loginEmailFailure("common.signin.email_password.email.invalid_email_address")
    }
    else if (authError.isWeakPassword(error)) {
        loginPasswordFailure("common.signin.email_password.password.weak_password")
    }
    else if (authError.isInvalidPassword(error)) {
        loginPasswordFailure("common.signin.email_password.password.wrong_password")
    }
    else {
        loginEmailFailure("common.signin.email_password.email.unknown_error")
        loginPasswordFailure("common.signin.email_password.email.unknown_error")
    }
}

const onSuccessSignUp = (email: string, newletterSubscription: boolean, user: any) => {
    if (newletterSubscription) {
        subscribeToNewsletter(email)
    }
}

const subscribeToNewsletter = (email: string) => {
    console.log(email)
}