import {
  accent_color,
  base_color,
  Button,
  Heading,
  size,
  space,
  Text,
  TextInput,
  useNormalize,
} from '@unboared/base-ui.all'
import { useScreenInfos } from '@unboared/utils.orientation'
import { useState } from 'react'
import { Pressable, View } from 'react-native'
import { DropDown } from '../../../components/input/dropdown'

export const InfosCreation = ({
  title,
  setTitle,
  durationBeforeStart,
  setDurationBeforeStart,
  onCancel,
  onValidate,
}: any) => {
  const { normalize } = useNormalize()
  const { width } = useScreenInfos()

  const [openDuration, setOpenDuration] = useState(false)
  const [durations, setDurations] = useState([
    { label: '0 sec', value: 0 },
    { label: '1 sec', value: 1 },
    { label: '2 sec', value: 2 },
    { label: '3 sec', value: 3 },
    { label: '5 sec', value: 5 },
    { label: '10 sec', value: 10 },
    { label: '15 min', value: 15 },
    { label: '20 min', value: 20 },
  ])

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        alignSelf: 'center',
        minWidth: normalize(300),
        maxWidth: normalize(0.9 * width),
      }}
    >
      <View></View>
      <View
        style={{
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Heading type="h2" text="Titre de votre Quizz" />
        <TextInput
          style={{
            width: '100%',
            borderRadius: normalize(size.borderRadius.small),
            fontSize: normalize(18),
          }}
          containerStyle={{
            width: '100%',
            marginVertical: normalize(space.medium),
            alignItems: 'center',
          }}
          placeholder="Indiquez votre titre ici..."
          text={title || ''}
          onChangeText={setTitle}
        />
        <Text
          style={{ color: base_color.light_secondary}}
          preset="large"
          text="Montrer la question avant d'afficher les propositions:"
        />
        <View style={{ marginVertical: normalize(space.smaller) }}>
          <DropDown
            placeholder="Durée"
            open={openDuration}
            value={durationBeforeStart}
            items={durations}
            setOpen={setOpenDuration}
            setValue={setDurationBeforeStart}
            setItems={setDurations}
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Pressable
          onPress={onCancel}
          style={{ width: '50%', alignItems: 'center' }}
        >
          <Heading type="h3">Annuler</Heading>
        </Pressable>
        <Button
          preset="large"
          textPreset="large"
          style={{
            marginLeft: normalize(space.small),
            backgroundColor: accent_color.success,
            width: '50%',
          }}
          text="Valider"
          onPress={onValidate}
          size={12}
        />
      </View>
    </View>
  )
}
