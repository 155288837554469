import React, { useEffect } from 'react'

import {
  BlurredOverlay,
  space,
  Text,
  useNormalize,
  useTheme,
} from '@unboared/base-ui.all'
import { useGlobalPopup } from './useGlobalPopup'
import { Pressable } from 'react-native'
import { UnQuizzContainer } from '../../container/unquizz_container'
import { useScreenInfos } from '@unboared/utils.orientation'

export const GlobalPopup = () => {
  const content = useGlobalPopup((state) => state.content)
  const close = useGlobalPopup((state) => state.close)
  const { normalize } = useNormalize()
  const theme = useTheme()
  const { width, isPortrait } = useScreenInfos()

  useEffect(() => {
    return () => close()
  }, [])

  return (
    <BlurredOverlay active={content}>
      <UnQuizzContainer
        style={{
          minWidth: isPortrait ? 0.9 * width : normalize(300),
          backgroundColor: theme.backgroundColor,
        }}
      >
        <>
          {content}
          <ClosePopup
            style={{
              position: 'absolute',
              right: normalize(space.smaller),
              top: normalize(space.smaller),
            }}
          />
        </>
      </UnQuizzContainer>
    </BlurredOverlay>
  )
}

const ClosePopup = ({ style }: any) => {
  const close = useGlobalPopup((state) => state.close)
  return (
    <Pressable style={style} onPress={close}>
      <Text preset="big" style={{ fontFamily: 'OpenSansBold' }} text="x" />
    </Pressable>
  )
}
