import React from 'react'
import { ChangePassword } from '../change_password'

export const Auth = ({ navigation, route }: any) => {
  console.log('AUTH PARAMS')
  const params = route.params
  console.log(params)
  // const [searchParams, setSearchParams] = useSearchParams()

  // Get the action to complete.
  const mode = params.mode
  // Get the one-time code from the query parameter.
  const actionCode = params.oobCode
  // (Optional) Get the continue URL from the query parameter if available.
  const continueUrl = params.continueUrl
  // (Optional) Get the language code if available.
  const lang = params.lang || 'en'

  let page
  // Handle the user management action.
  switch (mode) {
    case 'resetPassword':
      // Display reset password handler and UI.
      page = (
        <ChangePassword
          actionCode={actionCode}
          continueUrl={continueUrl}
          lang={lang}
        />
      )
      break
    case 'recoverEmail':
      // Display email recovery handler and UI.
      page = (
        <ChangePassword
          actionCode={actionCode}
          continueUrl={continueUrl}
          lang={lang}
        />
      )
      break
    case 'verifyEmail':
      // Display email verification handler and UI.
      page = (
        <ChangePassword
          actionCode={actionCode}
          continueUrl={continueUrl}
          lang={lang}
        />
      )
      break
    default:
    // Error: invalid mode.
    // 404 error
  }

  return page
}
