import React, { useState } from 'react'
import { useMargin } from '../hooks/useMargin'
import { ExtraSignInButtonWithLogo } from './button'
import { useAuth } from '../../../../services/auth'

const GoogleSignInUp = ({ tx, onSuccess }: { tx: string; onSuccess: any }) => {
  const { smallMargin } = useMargin()
  const loginWithGoogle = useAuth((state) => state.loginWithGoogle)

  return (
    <ExtraSignInButtonWithLogo
      tx={tx}
      logo="https://cdn-icons-png.flaticon.com/512/2965/2965278.png"
      logoSize={15}
      style={smallMargin}
      onPress={() =>
        loginWithGoogle()
          .then((user) => {
            if (onSuccess) {
              onSuccess(user)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    />
  )
}

export const GoogleSignIn = ({ onSuccessSignIn }: any) => (
  <GoogleSignInUp
    tx="common.signin.google.submitButton"
    onSuccess={onSuccessSignIn}
  />
)

export const GoogleSignUp = ({ onSuccessSignUp }: any) => (
  <GoogleSignInUp
    tx="common.signup.google.submitButton"
    onSuccess={onSuccessSignUp}
  />
)
