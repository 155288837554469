import { Pressable, View } from 'react-native'
import { Player } from '@unboared/design.all'
import { Logo } from './logo'
import { usePlayers } from '@unboared/lib'

export const Header = ({ style, logoStyle, children, onPress }: any) => {
  const customStyle = [
    {
      flex: 1,
      alignSelf: 'stretch',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    style,
  ]

  return (
    <View style={customStyle}>
      <Pressable style={{ justifyContent: 'center' }} onPress={onPress}>
        <Logo preset={logoStyle} size={12} />
      </Pressable>
      <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
        {children}
      </View>
    </View>
  )
}

export const GamepadHeader = () => {
  const { getUsername, getAvatar, getColor } = usePlayers()
  return (
    <View
      style={{
        alignSelf:'flex-start',
      }}
    >
      <Player
        username={getUsername()}
        avatar={getAvatar()}
        color={getColor()}
        size={30}
      />
    </View>
  )
}
