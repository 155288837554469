import {
  Button,
  Heading,
  space,
  Text,
  useNormalize,
  useTheme,
} from '@unboared/base-ui.all'
import {
  Image,
  Pressable,
  useWindowDimensions,
  View,
  ScrollView,
} from 'react-native'
import { useLinkTo } from '@react-navigation/native'

import HomeContainer from '../../components/container/home_container'
import Illustration from '../../assets/img/homeIllustration.png'
import { TopRightContent } from '../../components/top_right_content'
import { useAnalyticsAccessEvent } from '../../services/analytics'

const ILLUSTRATION_SCALE = 0.6
const ILLUSTRATION_WIDTH = ILLUSTRATION_SCALE * 747
const ILLUSTRATION_HEIGHT = ILLUSTRATION_SCALE * 454

export const Showcase = ({ navigation }: any) => {
  useAnalyticsAccessEvent('page_vitrine')

  const linkTo = useLinkTo()
  const { normalize } = useNormalize()
  const { width } = useWindowDimensions()
  const theme = useTheme()

  return (
    <>
      <HomeContainer onHome={() => navigation.navigate('Home')}>
        <View
          style={{
            maxWidth: Math.min(normalize(700), width),
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ScrollView
            style={{
              width: '100%',
            }}
          >
            <View
              style={{
                alignSelf: 'center',
                alignItems: 'center',
                marginVertical: normalize(space.larger),
                maxWidth: normalize(350),
              }}
            >
              <Heading
                type="h1"
                style={{
                  marginVertical: normalize(space.smaller),
                  textAlign: 'center',
                }}
                text="Votre quizz personnalisé pour les équipes ou étudiants"
              />
              <Text
                style={{
                  marginVertical: normalize(space.smaller),
                  textAlign: 'center',
                }}
                text="Créez gratuitement votre quizz en 2 clics et faites scanner un QR code pour faire participer votre audience !"
              />
              <Button textPreset='medium' text="CRÉER UN QUIZZ" onPress={() => linkTo('/create')} />
            </View>
            <Image
              source={Illustration}
              style={{
                height: normalize(ILLUSTRATION_HEIGHT),
                width: normalize(ILLUSTRATION_WIDTH),
              }}
            />
          </ScrollView>
        </View>
      </HomeContainer>
      <TopRightContent>
        <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
          <Pressable onPress={() => linkTo('/signup')}>
            <Text
              style={{
                fontWeight: 'bold',
                color: theme.buttonColor,
                paddingLeft: normalize(space.small),
              }}
            >
              S'inscrire
            </Text>
          </Pressable>
          <Pressable onPress={() => linkTo('/login')}>
            <Text
              style={{
                fontWeight: 'bold',
                paddingLeft: normalize(space.small),
              }}
            >
              Se connecter
            </Text>
          </Pressable>
        </View>
      </TopRightContent>
    </>
  )
}
