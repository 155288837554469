import {
  accent_color,
  Button,
  Heading,
  space,
  Text,
  TextInput,
  useNormalize,
} from '@unboared/base-ui.all'
import { useState } from 'react'
import { View } from 'react-native'
import Container from '../../components/container/main_container'
import { DropDown } from '../../components/input/dropdown'
import { ActivityIndicator } from '../../components/loaders'
import { useAuth } from '../../services/auth'
import { useActiveUser } from '../../services/user'
import { usersAPI } from '../../services/users_api'

export const UserSelectionAndLaunch = () => {
  const { normalize } = useNormalize()
  const auth = useAuth((state) => state.auth)
  const initialLoading = useAuth((state) => state.initialLoading)
  const { createUser, user, loading } = useActiveUser()
  const [errorMessage, setErrorMessage] = useState('')

  const launchUI = (
    username: string,
    activity: string,
    localMoreInfos: string,
  ) => {
    if (username.length >= 3 && activity) {
      if (!user) {
        createUser(auth.uid, username, {
          activity: activity,
          activityDetails: localMoreInfos,
        })
      } else {
        usersAPI.updateUserInfos(auth.uid, username, {
          activity: activity,
          activityDetails: localMoreInfos,
        })
      }
    } else {
      if (username.length < 3) {
        setErrorMessage(
          "Le nom d'utilisateur doit contenir au minimum 3 caractères",
        )
      } else if (!activity) {
        setErrorMessage('Veuillez spécifier votre activité')
      } else {
        setErrorMessage('Erreur inconnue')
      }
    }
  }

  const [localUsername, setLocalUsername] = useState('')
  const [localActivity, setLocalActivity] = useState('')
  const [localMoreInfos, setLocalMoreInfos] = useState('')

  const [open, setOpen] = useState(false)
  const [items, setItems] = useState([
    { label: 'Etudiant', value: 'student' },
    { label: 'Doctorant', value: 'phd' },
    { label: 'Professeur', value: 'professor' },
    { label: 'Ingénieur', value: 'engineer' },
    { label: 'Chercheur', value: 'researcher' },
    { label: 'Designer', value: 'designer' },
    { label: 'Consultant', value: 'consultant' },
    { label: "Chef d'entreprise", value: 'company_director' },
    { label: 'Autre', value: 'other' },
  ])

  const onSubmit = () => launchUI(localUsername, localActivity, localMoreInfos)

  if (initialLoading) {
    return (
      <View
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 100,
          elevation: 100,
        }}
      >
        {/* <AuthContainer> */}
        <ActivityIndicator />
        {/* </AuthContainer> */}
      </View>
    )
  }

  return (
    <>
      {auth && (!user || !user.username) && (
        <View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 100,
            elevation: 100,
          }}
        >
          <Container
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {loading ? (
              <ActivityIndicator />
            ) : (
              <View
                style={{
                  flex: 1,
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  minWidth: normalize(200),
                  maxWidth: normalize(300),
                }}
              >
                <TextInput
                  label="Mon pseudo"
                  containerStyle={{
                    width: '100%',
                    marginTop: normalize(space.small),
                  }}
                  style={{ borderRadius: normalize(20) }}
                  minLength={3}
                  maxLength={12}
                  text={localUsername}
                  placeholder={'pseudo ?'}
                  onChangeText={setLocalUsername}
                  onSubmitEditing={onSubmit}
                  // helperText={errorMessage}
                />
                <View
                  style={{
                    width: '100%',
                    marginTop: normalize(space.small),
                    zIndex: 999,
                    elevation: 999,
                  }}
                >
                  <Heading type="h4" text="Vous êtes:" />
                  <DropDown
                    placeholder="Mon activité"
                    open={open}
                    value={localActivity}
                    items={items}
                    setOpen={setOpen}
                    setValue={setLocalActivity}
                    setItems={setItems}
                  />
                </View>
                {localActivity === 'other' && (
                  <TextInput
                    label="(Autre) Veuillez préciser"
                    containerStyle={{
                      width: '100%',
                      marginTop: normalize(space.small),
                    }}
                    style={{ borderRadius: normalize(20) }}
                    minLength={3}
                    maxLength={100}
                    text={localMoreInfos}
                    placeholder={'précisez ici votre activité'}
                    onChangeText={setLocalMoreInfos}
                  />
                )}
                <Button
                  style={{
                    marginTop: normalize(space.small),
                    zIndex: 0,
                    elevation: 0,
                  }}
                  text="Valider"
                  onPress={onSubmit}
                />
                {errorMessage && (
                  <Text
                    style={{
                      color: accent_color.error,
                      marginTop: normalize(space.small),
                    }}
                    text={errorMessage}
                  />
                )}
              </View>
            )}
          </Container>
        </View>
      )}
    </>
  )
}
