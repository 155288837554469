import { Platform, StyleSheet, View } from 'react-native'
import { useLinkTo } from '@react-navigation/native'

import {
  space,
  useNormalize,
  Button,
  accent_color,
} from '@unboared/base-ui.all'
import { useFullscreen } from '@unboared/utils.fullscreen'

import { iOS } from '../../../utils'
import { UnQuizzContainer } from '../../container/unquizz_container'

import { PreviewInfos } from './preview_infos'
import { DeleteQuizzButton } from './delete_quizz_button'
import { useCallback } from 'react'
import { useGlobalPopup } from '../../popup/global_popup'
import { DeleteConfirmationPopup } from './delete_confirmation_popup'
import { useActiveUser } from '../../../services/user'

/** The quizz preview that will be displayed in your home. */
export const QuizzPreview = ({ uuid, author, infos, questions }: any) => {
  const linkTo = useLinkTo()
  const { normalize } = useNormalize()
  const { isFullscreen, toggleFullscreen } = useFullscreen()
  const { open, close } = useGlobalPopup()
  const { user } = useActiveUser()

  const launchQuizz = useCallback(() => {
    if (Platform.OS == 'web' && !iOS() && !isFullscreen) {
      toggleFullscreen()
    }
    linkTo(`/quizz/${uuid}`)
  }, [])

  const confirmDeletion = () => {
    open(
      <DeleteConfirmationPopup
        name={infos.name}
        uuid={uuid}
        uid={user?.uid}
        author={author}
        questions={questions}
        onCancel={close}
      />,
    )
  }

  return (
    <UnQuizzContainer style={styles(normalize).mainContainer}>
      <PreviewInfos infos={infos} questions={questions} />
      <View style={styles(normalize).buttonsContainer}>
        {user && user.uid === author && (
          <View style={styles(normalize).buttonContainer}>
            <Button
              // textPreset="medium"
              text="MODIFIER"
              onPress={() => linkTo(`/modify/${uuid}`)}
            />
          </View>
        )}
        <View style={styles(normalize).buttonContainer}>
          <Button
            // textPreset="medium"
            text="LANCER"
            style={{ backgroundColor: accent_color.success }}
            onPress={launchQuizz}
          />
        </View>
      </View>
      {/* {user && user.uid === author && ( */}
      <DeleteQuizzButton
        style={{
          position: 'absolute',
          right: normalize(space.medium),
        }}
        onPress={confirmDeletion}
      />
      {/* )} */}
    </UnQuizzContainer>
  )
}

const styles = (normalize: (x: number) => number) =>
  StyleSheet.create({
    mainContainer: {
      margin: normalize(space.smaller),
      padding: normalize(space.medium),
      width: normalize(200),
    },
    buttonsContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: normalize(space.medium),
    },
    buttonContainer: {
      flex: 1,
      paddingHorizontal: normalize(space.tiny),
    },
  })
