
// ---- ASSETS ----
import { initializeApp } from 'firebase/app'
import logoUnQuizzByUnboaredPNG from './assets/img/logo.png'
import logoUnQuizzPNG from './assets/img/unquizz.png'

export const LogoUnQuizzByUnboared = logoUnQuizzByUnboaredPNG
export const LogoUnQuizz = logoUnQuizzPNG


// ---- DATA CONFIGURATIONS ----

export const DEFAULT_QUESTION_DURATION = 20 // durée d'une question par défault
export const DEFAULT_DURATION_BEFORE_START = 0 // durée d'affichage des questions avant de lancer le chrono

export enum QuizzCreationType {
  INFOS,
  QUESTIONS,
}


// ---- UI CONFIGURATIONS ----
import { player_color } from '@unboared/base-ui.all'

export const BUTTON_COLORS = [player_color[0], player_color[2], player_color[1], ...player_color.slice(3)]
export const DISABLE_OPACITY = 0.3

export const SPACE = {
  tiny: 2,
  smaller: 5,
  small: 10,
  medium: 15,
  large: 20,
}


// ---- FIREBASE CONFIGURATIONS ----

import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage"

export const firebaseConfig = {

  apiKey: "AIzaSyA73uw5UXSfGQYGD-KFFXTy8aBlGLHGlGY",

  authDomain: "unquizz.firebaseapp.com",

  projectId: "unquizz",

  storageBucket: "unquizz.appspot.com",

  messagingSenderId: "781095095243",

  appId: "1:781095095243:web:fdd3ffb1fb7a5885b7c44e",

  measurementId: "G-367B6KVVF5"

};

const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

// Initialize Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Firestore and get a reference to the service
const db = getFirestore(app)

// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);

export { analytics, auth, db, storage }


// ---- FUNCTIONALITIES TO GENERATE UNGUYS ----

export const SEED_LENGTH = 8

export function makeid(length: number) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const getAdventurerURL = (seed: string) => {
  return `https://api.dicebear.com/5.x/adventurer/png?seed=${seed}&backgroundColor=transparent`;
};

export const sampleAvatar = (): string => {
  const seed = makeid(SEED_LENGTH);
  return getAdventurerURL(seed);
};
