import React from 'react'
import { Pressable, StyleSheet } from 'react-native'
import { Heading, useNormalize, size } from '@unboared/base-ui.all'
import { BUTTON_COLORS, DISABLE_OPACITY } from '../../const'

export type ProposalProps = {
    id: number
    disable?: boolean
    onPress?: any
    style?: any
}

/**
 * A component to display proposals on the gamepad.
 */
export const ProposalButtonGamepad = ({
  id,
  style,
  disable,
  onPress,
}: ProposalProps) => {
  const A_code = 65
  const { normalize } = useNormalize()

  return (
    <Pressable
      style={[styles(normalize, disable, id).button, style]}
      onPress={onPress}
    >
      <Heading selectable={false}>{String.fromCharCode(A_code + id)}</Heading>
    </Pressable>
  )
}

const styles = (
  normalize: (x: number) => number,
  disable?: boolean,
  id?: number,
) =>
  StyleSheet.create({
    button: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: BUTTON_COLORS[id || 0],
      opacity: disable ? DISABLE_OPACITY : 1,
      borderRadius: normalize(size.borderRadius.small),
    },
  })
