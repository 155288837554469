import {
  base_color,
  Button,
  Heading,
  space,
  Text,
  useNormalize,
  useTheme,
} from '@unboared/base-ui.all'
import { Pressable, ScrollView, View } from 'react-native'
import Container from '../../components/container/main_container'
import { useAuth } from '../../services/auth'
import { useActiveUser } from '../../services/user'
import { UnQuizzContainer } from '../../components/container/unquizz_container'
import { NavBar } from '../../components/navbar'
import { useAnalyticsAccessEvent } from '../../services/analytics'

/** The screen with user/account settings */
export function SettingsScreen({ navigation }: any) {
  useAnalyticsAccessEvent('settings')

  const { logout: logoutAuth } = useAuth()
  const { user, logout: logoutUser } = useActiveUser()
  const { normalize } = useNormalize()

  const logout = () => {
    logoutAuth()
    logoutUser()
  }

  return (
    <>
      <Container>
        <ScrollView
          style={{
            flex: 1,
            marginTop: normalize(space.huge),
            alignSelf: 'stretch',
          }}
        >
          <Heading
            style={{ marginVertical: normalize(space.small) }}
            text={`Bonjour ${user.username} 👋`}
          />
          <Text
            style={{ color: base_color.light_secondary }}
            preset="large"
            text="Mon compte"
          />
          <View
            style={{
              flex: 1,
              alignSelf: 'stretch',
              marginVertical: normalize(space.small),
              flexWrap: 'wrap',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Pressable onPress={logout}>
              <UnQuizzContainer
                style={{
                  margin: normalize(space.smaller),
                  padding: normalize(space.small),
                  width: normalize(200),
                  alignItems: 'center',
                }}
              >
                <Text
                  preset="large"
                  text="Déconnexion"
                />
              </UnQuizzContainer>
            </Pressable>
            <UnQuizzContainer
              style={{
                margin: normalize(space.smaller),
                padding: normalize(space.small),
                width: normalize(200),
                backgroundColor: '#FF245380',
                alignItems: 'center',
              }}
            >
              <Text
                preset="large"
                text="Supprimer le compte"
              />
            </UnQuizzContainer>
          </View>
        </ScrollView>
      </Container>
      <NavBar activeNav="settings" />
    </>
  )
}
