import { DEFAULT_QUESTION_DURATION } from "../../const";
import { UnQuizzState } from "../../quizz/inQuizz/components/UnQuizz";
import { Question } from "./Question";

export enum QuestionType {
    QCM = "qcm",
    QCU = "qcu",
    VRAI_FAUX = "vrai_faux",
    QR = "qr",
}

export const DEFAULT_INITIAL_QUESTION = { type: QuestionType.QCU, question: '', duration: DEFAULT_QUESTION_DURATION }

export type QuestionGamepadProps = {
    question: Question
    vote: unknown
    onVote: (vote: unknown) => void
    gameState: UnQuizzState
    canVote: boolean
}
