import { useTheme, DropDown as DropDownUnboared } from '@unboared/base-ui.all'

export const DropDown = (props: any) => {
  const { style, ...rest } = props
  const theme = useTheme()

  return (
    <DropDownUnboared
      style={{
        minHeight: 0,
        minWidth: 0,
        backgroundColor: theme.backgroundColor,
      }}
      theme="DARK"
      {...rest}
    />
  )
}
