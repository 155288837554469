import { useLinkTo } from '@react-navigation/native'
import {
  Button,
  Text,
  space,
  useNormalize,
  useTheme,
} from '@unboared/base-ui.all'
import { Pressable, TextStyle, View } from 'react-native'
import { TopRightContent } from '../top_right_content'

const NavItem = ({ onPress, text, active }: any) => {
  const { normalize } = useNormalize()
  const theme = useTheme()

  const style = active
    ? ({
        marginLeft: normalize(space.small),
        borderBottomWidth: normalize(2),
        borderColor: theme.buttonColor,
        fontWeight: 'bold',
      } as TextStyle)
    : ({
        marginLeft: normalize(space.small),
        fontWeight: 'bold',
      } as TextStyle)

  return (
    <Pressable onPress={onPress}>
      <Text style={style}>{text}</Text>
    </Pressable>
  )
}

export const NavBar = ({ activeNav }: any) => {
  const linkTo = useLinkTo()
  const { normalize } = useNormalize()

  return (
    <TopRightContent>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <NavItem
          text="Mes Quizz"
          onPress={() => linkTo('/me')}
          active={activeNav === 'me'}
        />
        <NavItem
          text="Mon compte"
          onPress={() => linkTo('/settings')}
          active={activeNav === 'settings'}
        />
        <Button
          icon="add"
          textPreset="medium"
          style={{
            marginLeft: normalize(space.small),
          }}
          text="Nouveau Quizz"
          onPress={() => linkTo('/create')}
        />
      </View>
    </TopRightContent>
  )
}
