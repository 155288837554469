import { useLinkTo } from '@react-navigation/native'
import { space, useNormalize, useTheme } from '@unboared/base-ui.all'
import { useScreenInfos } from '@unboared/utils.orientation'
import { View } from 'react-native'
import { Header } from '../header'

/** Container when the user is authentified */
export default function Container({ style, children, hideHeader }: any) {
  const theme = useTheme()
  const linkTo = useLinkTo()
  const { normalize } = useNormalize()
  const { isPortrait } = useScreenInfos()

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        backgroundColor: theme.backgroundColor,
        padding: normalize(space.medium),
      }}
    >
      {hideHeader || (
        <Header
          style={{ flex: isPortrait ? 0.1 : 0.05 }}
          onPress={() => linkTo('/me')}
        />
      )}
      <View
        style={[
          {
            flex: isPortrait ? 0.9 : 0.95,
            alignItems: 'center',
            alignSelf: 'stretch',
          },
          style,
        ]}
      >
        {children}
      </View>
    </View>
  )
}
