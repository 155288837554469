import {
  Heading,
  size,
  space,
  Text,
  transparency,
  useNormalize,
} from '@unboared/base-ui.all'
import { View } from 'react-native'
import { QCM } from './qcm'
import { ProposalButton } from '../../../components/buttons/proposal_button'
import { Question } from '../Question'
import { useEffect, useState } from 'react'

/**
 * The component on the gamepad (related to the type of question)
 * @param {param QuestionGamepadProps} param props - properties for question gamepad
 * @returns the QCM gamepad component
 */
export const QcmAnswer = ({
  question,
  answers,
}: {
  question: Question
  answers: any
}) => {
  let qcm = question as QCM
  const { normalize } = useNormalize()
  const [counts, setCounts] = useState<Array<number>>([])

  console.log(answers)

  useEffect(() => {
    if (qcm && answers) {
      let allAnswers = Object.values(answers).reduce(
        (prev: Array<unknown>, current: unknown) => prev.concat(current),
        [],
      )
      const n_proposals = qcm.getProposals().length
      let counts = Array(n_proposals).fill(0)
      for (let i = 0; i < n_proposals; i++) {
        counts[i] = allAnswers.filter(
          (a: unknown) => a === qcm.getProposal(i),
        ).length
      }
      setCounts(counts)
    }
  }, [qcm, answers])

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {qcm.getProposals().length > 0 &&
        qcm.getProposals().map((proposal, i) => (
          <View
            key={i}
            style={{ width: '50%', padding: normalize(space.smaller) }}
          >
            <ProposalButton
              id={i}
              disable={!qcm.includeAnswer(qcm.getProposal(i))}
            >
              <Heading
                type="h3"
                style={{ fontFamily: 'OpenSansBold', fontSize: normalize(15) }}
                text={proposal}
                numberOfLines={2}
                ellipsizeMode="tail"
              />

              {counts.length > i && (
                <Heading type="h1" text={counts[i].toString()} />
              )}
            </ProposalButton>
          </View>
        ))}
      {qcm.getAnecdote() && (
        <View
          style={{
            maxWidth: '80%',
            marginTop: normalize(space.small),
            borderRadius: normalize(size.borderRadius.small),
            padding: normalize(space.smaller),
            backgroundColor: transparency('dark', 0.2),
          }}
        >
          <Text
            style={{ textAlign: 'center' }}
            numberOfLines={5}
            preset="big"
            text={`💡 ${qcm.getAnecdote()}`}
          />
        </View>
      )}
    </View>
  )
}
