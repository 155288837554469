
export const addOrRemove = (value: unknown, list: Array<unknown>) => {
    let idx = list.findIndex((elem: unknown) => elem == value)
    if (idx === -1) {
        list.push(value)
    } else {
        list.splice(idx, 1)
    }
}

export function shuffle(array: Array<unknown>) {
    let currentIndex = array.length,
        randomIndex

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

            // And swap it with the current element.
            ;[array[currentIndex], array[randomIndex]] = [
                array[randomIndex],
                array[currentIndex],
            ]
    }

    return array
}