import { useNormalize, useTheme } from '@unboared/base-ui.all'
import { Image } from 'react-native'
import { LogoUnQuizz, LogoUnQuizzByUnboared } from '../const'

export const Logo = ({
  preset = 'primary',
  size,
}: {
  preset?: string
  size?: number
}) => {
  const { normalize } = useNormalize()
  const theme = useTheme()

  return (
    <Image
      source={preset === 'primary' ? LogoUnQuizz : LogoUnQuizzByUnboared}
      style={{
        height: normalize(0.8*39),
        width: normalize(0.8*119),
      }}
      // alt="UnQuizz"
    />
  )
}
