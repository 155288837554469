import React from 'react'
import { Pressable, View, ViewStyle } from 'react-native'

import {
  useNormalize,
  useTranslate,
  Text,
  useTheme,
  base_color,
} from '@unboared/base-ui.all'

import { useMargin } from './hooks/useMargin'
import { EmailPasswordSignIn } from './components/email_password'
import { GoogleSignIn } from './components/google'
import { useAuth } from '../../../services/auth'
import { ActivityIndicator } from '../../../components/loaders'
import { useLinkTo } from '@react-navigation/native'
import HomeContainer from '../../../components/container/home_container'
import { useAnalyticsAccessEvent } from '../../../services/analytics'

/**
 * Sign in page.
 */
export const SignIn = ({ onHome, onSignup, onSuccessSignIn }: any) => {
  const loading = useAuth((state) => state.loading)
  useAnalyticsAccessEvent('singin')

  const styles = {
    container: {
      flexDirection: 'row',
    } as ViewStyle,
    flexCenter: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    } as ViewStyle,
    content: {
      minWidth: '80%',
      maxWidth: '80%',
      justifyContent: 'center',
    } as ViewStyle,
  }

  return (
    <HomeContainer onHome={onHome}>
      <View style={[styles.flexCenter, styles.container]}>
        <View style={styles.flexCenter}>
          {loading ? (
            <ActivityIndicator />
          ) : (
            <View style={styles.content}>
              <EmailPasswordSignIn onSuccessSignIn={onSuccessSignIn} />
              <Separator />
              <GoogleSignIn onSuccessSignIn={onSuccessSignIn} />
              <CreateAccountLink goToSignUp={onSignup} />
            </View>
          )}
        </View>
      </View>
    </HomeContainer>
  )
}

/**
 * The link to the signup page.
 */
const CreateAccountLink = ({ goToSignUp }: any) => {
  const { translate } = useTranslate()
  const { largeMargin } = useMargin()
  const theme = useTheme()

  const linkTo = useLinkTo()
  const resetFields = useAuth((state) => state.resetFields)

  return (
    <Pressable
      onPress={() => {
        resetFields()
        if (goToSignUp) {
          goToSignUp()
        } else {
          linkTo('/signup')
        }
      }}
    >
      <Text
        style={{
          ...largeMargin,
          fontFamily: 'OpenSansBold',
          alignSelf: 'center',
        }}
      >
        {translate('common.signin.noAccount.message')}{' '}
        <Text
          style={{
            color: theme.color.primary,
            fontFamily: 'OpenSansBold',
          }}
          tx="common.signin.noAccount.createAccount"
        />
      </Text>
    </Pressable>
  )
}

const Separator = () => {
  const theme = useTheme()
  const { normalize } = useNormalize()
  const { smallMargin } = useMargin()

  return (
    <Text
      tx="common.signin.or"
      style={{
        ...smallMargin,
        alignSelf: 'center',
        fontFamily: 'OpenSansBold',
        color: base_color.light_secondary,
      }}
    />
  )
}

export default SignIn
