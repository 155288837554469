import { size, space, transparency, useNormalize } from '@unboared/base-ui.all'
import { View } from 'react-native'

/** Specific Unquizz container with white border. */
export const UnQuizzContainer = ({ style, children }: any) => {
  const { normalize } = useNormalize()

  return (
    <View
      style={[
        {
          padding: normalize(space.smaller),
          borderColor: '#FFF',
          borderWidth: normalize(2),
          borderRadius: normalize(size.borderRadius.small),
          backgroundColor: transparency('dark', 0.1),
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}
