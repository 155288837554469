import { db as firebaseDB } from '.'
import {
  DocumentData,
  DocumentReference,
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import { User } from '../user'

export const usersAPI = {
  /* MANAGE USERS */

  /**
   * This function creates a new user in the database.
   * @param user the user data
   * @returns a Promise
   */
  createUser: async (user: any) => {
    const docRef = doc(firebaseDB, 'users', user.uid)
    await setDoc(docRef, user, { merge: true })
  },

  /**
   * This function update the informations relative to a user.
   * @param uid the unique user id
   * @param username the username
   * @param infos other informations avec the user
   */
  updateUserInfos: async (uid: any, username: string, infos: any) => {
    const authorRef = doc(firebaseDB, 'users', uid)
    updateDoc(authorRef, {
      username: username,
      infos: infos,
    })
  },

  /**
   * Get the user from its id.
   * @param uid the user identifier
   * @returns a promise
   */
  getUser: async (uid: string) => {
    const docRef = doc(firebaseDB, 'users', uid)
    let item = await getDoc(docRef)
    return item.data()
  },

  /* MANAGE QUIZZES */

  /**
   * This function add a quizz in the database.
   * @param quizz the quizz
   * @returns the document ID
   */
  createQuizz: async (quizz: any) => {
    const authorRef = doc(firebaseDB, 'users', quizz.author)
    quizz.author = authorRef
    const docRef = await addDoc(collection(firebaseDB, 'quizz'), quizz)
    const docID = docRef.id
    await updateDoc(docRef, { uuid: docID })

    updateDoc(authorRef, {
      quizz: arrayUnion(doc(firebaseDB, 'quizz/' + docID)),
    })

    return docID
  },

  /**
   * This function update a quizz in the database
   * @param quizz the quizz
   */
  modifyQuizz: async (quizz: any) => {
    quizz.author = doc(firebaseDB, 'users/', quizz.author)
    const uuid = quizz.uuid // can be undefined if the quizz does not exists
    const docRef = doc(firebaseDB, 'quizz', uuid)
    await updateDoc(docRef, quizz)
    const docID = docRef.id

    await updateDoc(quizz.author, {
      quizz: arrayRemove(doc(firebaseDB, 'quizz/' + docID)),
    })
    updateDoc(quizz.author, {
      quizz: arrayUnion(doc(firebaseDB, 'quizz/' + docID)),
    })
  },

  /**
   * This function remove a quizz in the database
   * @param uid the unique user id
   * @param quizzID the quizz identifier
   */
  removeQuizz: async (uid: string, quizzID: string) => {
    const docRef = doc(firebaseDB, 'users', uid)
    const docQuizzRef = doc(firebaseDB, 'quizz', quizzID)
    const quizzData = (await getDoc(docQuizzRef)).data()
    await updateDoc(docRef, {
      quizz: arrayRemove(doc(firebaseDB, 'quizz/' + quizzID)),
    })
    if (quizzData && quizzData.author.id === uid) {
      await deleteDoc(doc(firebaseDB, 'quizz', quizzID))
    }
  },

  /**
   * Get a quizz data from the database
   * @param id the quizz identifier
   * @returns the quizz data
   */
  getQuizz: async (id: string | DocumentReference<DocumentData>) => {
    const docRef = typeof id === 'string' ? doc(firebaseDB, 'quizz', id) : id
    let quizz
    try {
      let quizzItem = await getDoc(docRef)
      quizz = quizzItem.data()
      if (quizz) {
        quizz.author = quizz.author.id
      }
    } catch (error) {
      console.error(error)
    }
    return quizz
  },

  /**
   * Get the quizz list related to an user.
   * @param uid the unique user identifier
   * @returns
   */
  getQuizzList: async (uid: string) => {
    let myQuizzes: any = []
    const docRef = doc(firebaseDB, 'users', uid)
    try {
      let userItem = await getDoc(docRef)
      let quizzes = (userItem.data() as any).quizz
      for (let quizzRef of quizzes) {
        let quizz = await usersAPI.getQuizz(quizzRef)
        if (quizz) {
          quizz.id = quizzRef.id
        }
        myQuizzes.push(quizz)
      }
    } catch (error) {
      console.error(error)
    }
    return myQuizzes
  },

  /* MANAGE TAGS */

  getAllTags: async () => {
    let tagList: any[] = []
    const querySnapshot = await getDocs(collection(firebaseDB, 'tags'))
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, ' => ', doc.data())
      tagList.push({ id: doc.id, ...doc.data() })
    })
    return tagList
  },

  getTag: async (tagID: string) => {
    const docRef = doc(firebaseDB, 'tags', tagID)
    try {
      let tagItem = await getDoc(docRef)
      return tagItem.data()
    } catch (error) {
      console.error(error)
    }
  },
}

export const useUser = (id: string) => {
  const [user, setUser] = useState<User>()

  const loadUser = useCallback(async (id: string) => {
    if (id) {
      usersAPI
        .getUser(id)
        .then(setUser)
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  useEffect(() => {
    loadUser(id)
  }, [id])

  return user
}

export const useQuizz = (id: string) => {
  const [quizz, setQuizz] = useState<any>(undefined)
  useEffect(() => {
    const loadQuizz = (id: string) => {
      if (id) {
        try {
          ;(async () => {
            const q = await usersAPI.getQuizz(id)
            setQuizz(q)
          })()
        } catch (error) {
          console.log(error)
        }
      }
    }
    loadQuizz(id)
  }, [id])

  return quizz
}

export const useQuizzList = (uid: string) => {
  const [quizz, setQuizz] = useState<any>(undefined)

  useEffect(() => {
    if (uid) {
      const docRef = doc(firebaseDB, 'users', uid)
      const unsub = onSnapshot(docRef, (doc) => {
        try {
          ;(async () => {
            const quizzes = await usersAPI.getQuizzList(uid)
            setQuizz(quizzes.reverse())
          })()
        } catch (error) {
          console.log(error)
        }
      })
      return unsub
    }
  }, [uid])

  useEffect(() => {
    const loadQuizzList = (uid: string) => {
      if (uid) {
        try {
          ;(async () => {
            const quizzes = await usersAPI.getQuizzList(uid)
            setQuizz(quizzes.reverse())
          })()
        } catch (error) {
          console.log(error)
        }
      }
    }
    loadQuizzList(uid)
  }, [uid])

  return quizz || undefined
}

export const useAllTags = () => {
  const [recipes, setRecipes] = useState<any>(undefined)
  useEffect(() => {
    const loadTags = () => {
      try {
        ;(async () => {
          const rec = await usersAPI.getAllTags()
          setRecipes(rec)
        })()
      } catch (error) {
        console.log(error)
      }
    }
    loadTags()
  }, [])

  return recipes || []
}
